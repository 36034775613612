<template>
	<section class="content">
		<div class="row justify-content-center">
			<div class="col-md-11">
				<div class="card card-primary">
					<!-- form start -->
					<form role="form" @submit.prevent="loadPin">
					<!-- <form role="form" @submit.prevent="loadOTP"> -->
						<div class="card-body">
							<p v-if="errors.length">
								<b>Please correct the following error(s):</b>
								<ul>
									<li v-for="error in errors" v-bind:key="error">{{ error }}</li>
								</ul>
							</p>

							<div class="alert alert-warning">
								<!-- Mohon menggunakan Bank BCA, BRI atau Mandiri supaya FREE biaya penarikan saldo.<br> -->
								Mohon menggunakan Bank BCA, BRI, Mandiri atau e-wallet (OVO, Shopeepay, Gopay, LinkAja, Dana) supaya FREE biaya penarikan saldo.<br>
								Selain Bank tersebut dikenakan biaya transfer <strong>Rp 4.000</strong> setiap penarikan saldo
							</div>
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group">
										<label class="control-label">NAMA BANK</label>
										<select id="bankid" v-model="form.bank_id" class="form-control">
											<option v-for="role in bank" v-bind:value="role.id" v-bind:key="role.id">
												{{ role.label }}
											</option>
										</select>
									</div>
									<div class="form-group">
										<label class="control-label">NO. REKENING</label>
										<input id="no_rek" class="form-control" v-model="form.bank_rekening"
											type="number" required="required" name="bank_rekening"
											onKeyPress="if(this.value.length==24) return false;"
											placeholder="Isi Nomor Rekening" autocomplete="off" 
											onkeydown="return (event.ctrlKey || event.altKey 
											|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
											|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
											|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
									</div>
									<div class="form-group">
										<label class="control-label">ATAS NAMA (Harus sama persis dengan di buku tabungan)</label>
										<input id="atasnama" class="form-control" v-model="form.bank_atasnama"
											type="text" required="required" name="bank_atasnama" autocomplete="off" 
											onkeydown="return (event.ctrlKey || event.altKey 
											|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
											|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
											|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (64<event.keyCode && event.keyCode<91))"/>
									</div>
									<div class="form-group">
										<label class="control-label">NO. TELEPON (Harus terdaftar di WhatsApp)</label>
										<input id="telepon" class="form-control" v-model="form.bank_telepon"
											type="text" required="required" name="bank_telepon" autocomplete="off" disabled="disabled"/>
									</div>
								</div>
								<div class="col-sm-6 d-none">
									<div class="card-body p-1 user-profile">
										<img style="object-fit: cover; height: 250px; width: 100%" alt="image"
											id="imgPreview" class="img-fluid img-responsive"
											src="http://localhost/boscod/app/backend/api/uploads/default/unknown_user.jpg" />
										<div id="loading-avatar" class="center-profile" style="display: none">
											<div class="btn-profile">
												<div class="spinner-border spinner-border-sm" role="status">
													<span class="sr-only">Loading...</span>
												</div>
											</div>
										</div>
										<div id="remove-avatar" class="center-profile">
											<a id="del-avatar" class="btn-profile remove-profile text-danger"
												title="Hapus Foto" @click="removeAvatar"><i class="fa fa-times"></i></a>
										</div>
									</div>
									<input name="file" type="file" id="inputFile" class="inputfile"
										@change="uploadFoto(this)" />
									<label for="file" class="btn btn-block btn-success" @click="browsefile()"><i
											class="fa fa-image"></i> Upload Foto KTP</label>
								</div>
							</div>
						</div>
						<div class="overlay" v-if="false"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
						<div class="card-footer">
							<div class="row mt-2 mb-2">
								<div class="col-xl-12 col-md-12">
									<div	class="callout callout-warning">>
										Harap dicatat bahwa ketika kamu sudah menyimpan detail
										pembayaran bank, kamu tidak bisa menggantinya lagi. Kamu
										hanya bisa menggantinya dengan menghubungi bantuan.
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<button type="submit" id="submit" class="btn btn-lg btn-primary mr-1" v-if="this.status == 'create'">
										Simpan
									</button>
								</div>
								<div class="col-12">
									<button type="submit" id="update" class="btn btn-lg btn-primary mr-1" v-if="this.status == 'update'">
										Ajukan Perubahan
									</button>
									<button type="disabled" class="btn btn-sm btn-link mr-1 d-block" v-if="this.status == 'update'" disabled>
										Note : Proses sekitar 3x24 jam
									</button>
								</div>
								<div class="col-12">
									<button type="disabled" id="updated" class="btn btn-lg btn-primary mr-1 btn-disabled" v-if="false" disabled>
										Perubahan sedang diproses
									</button>
								</div>
								<div class="col-12">
									<button type="button" class="btn btn-sm btn-link mr-1 d-block" v-if="false" @click="cancelRequest">
										Batalkan Perubahan
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<Pin :showPin="showPin" :messagePin="messagePin" v-on:change="loadOTP" />
		<OneTimePassword v-on:change="handleCustomChange" v-bind:params="{
			phoneNumber: form.bank_telepon,
			otpType: form.otpType,
			countShowOTP: countShowOTP,
			}" />
	</section>
</template>
<script>
import Vue from "vue";
import { authFetch, auth } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "icheck-material";
import Pin from "@/dialog/Pin";
// import Dropzone from "dropzone";
// import "dropzone/dist/dropzone.css";
import OneTimePassword from "@/dialog/OneTimePassword";

export default {
data() {
	return {
		errors: [],
		loadingContent: false,
		roles: "",
		groups: [],
		bank: [],
		show: false,
		method: "PUT",
		showPin: false,
		loadingPin: false,
		messagePin: 'ubah rekening',
		form: {
			id: "",
			bank_id: "",
			bank_rekening: "",
			bank_atasnama: "",
			bank_telepon: "",
			code: '',
			otpType: ''
			// file_ktp: "",
		},
		status: "create",
		// form_data : "",
		submitting1: false,
		submitting2: false,
		countShowOTP: 0,
	};
},
created: function () {
	this.userInfo = auth.user();
	let id = this.userInfo.id;
	this.loadRekening(id);

	authFetch("/shipper/bank")
	.then((res) => {
		if (res.status === 201) {
		} else if (res.status === 400) {
		}
		return res.json();
	})
	.then((js) => {
		this.bank = js.data;
	});

	authFetch("/shipper/isset_pin")
	.then((res) => {
		if (res.status === 201) {
		} else if (res.status === 400) {
		}
		return res.json();
	})
	.then((js) => {
		if (!js.success) {
			this.showPin = !this.showPin;
		}
	});
},
methods: {
	loadPin: function (ev) {
		this.showPin = !this.showPin;
		ev.preventDefault();
	},
	
	handleCustomChange (s) {
		this.form.code = s;
		this.submitForm();
		// this.postData();
	},
	loadOTP: function (evn) {
		$("#update").prop("disabled", true);
		$("#submit").prop("disabled", true);
		this.form.otpType = 'change_rekening';
		this.countShowOTP += 1;
		evn.preventDefault();
	},
	closeOPT: function (evn) {
		this.form.otpType = 'change_rekening';
		// this.showOTP = true;
		this.countShowOTP = 0;
		evn.preventDefault();
	},
	loadRekening(id) {
		authFetch('/users/profile/rekening').then((res) => {
			res.json().then((json) => {
				if (
					(json.bank_id == null || json.bank_id == 0 || json.bank_id == "") &&
					(json.bank_rekening == "" || json.bank_rekening == null) &&
					(json.bank_atasnama == "" || json.bank_atasnama == null)
				) {
					this.form.id = json.id;
					this.form.bank_id = "";
					this.form.bank_rekening = "";
					this.form.bank_atasnama = "";
					this.form.bank_telepon = json.bank_telepon;
				} else {
					this.form.id = json.id;
					this.form.bank_id = json.bank_id;
					this.form.bank_rekening = json.bank_rekening;
					this.form.bank_atasnama = json.bank_atasnama;
					this.form.bank_telepon = json.bank_telepon;
					this.status = "update";
					$("#submit").prop("disabled", true);
					if (
						json.bank_idnew != null &&
						json.bank_rekeningnew != null &&
						json.bank_atasnamanew != null
					) {
						this.status = "updated";
						$("#bankid").prop("disabled", true);
						$("#no_rek").prop("readonly", true);
						$("#atasnama").prop("readonly", true);
						$("#telepon").prop("readonly", true);
					}
				}

				if (json.file_path) {
					$("#imgPreview").attr(
						"src",
						window.$apiUrl+"/uploads/" +
						json.file_path +
						"/" +
						json.file_name
					);
				}

				$('.loading-overlay').removeClass('show');
			});
		});
	},
	postData() {
		var data = Object.keys(this.form)
			.map(
			(key) =>
				encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
			)
			.join("&");
		this.method = "PUT";
		let urlSubmit = this.status != "update" ? "/users/profile/rekening/" + this.form.id : "/payment/saldo/req_change_rek";
		authFetch(urlSubmit, {
			method: this.method,
			body: data,
		}).then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {
			}
			return res.json();
		}).then((js) => {
			if (!js.success) {
				for (var key in js.details) {
					if (js.details.hasOwnProperty(key) && js.close) {
						this.errors.push(`${js.details[key]}`);
					}else if(!js.close){
						Swal.fire({
							title: "Update Gagal",
							icon: "error",
							text: js.details[key],
							showCloseButton: true,
						});
					}
				}
				$("#update").prop("disabled", false);
				$("#submit").prop("disabled", false);
			} else {
				Swal.fire({
					title: this.status != "update" ? "Update Berhasil" : "Pengajuan Berhasil",
					icon: "success",
					text: this.status != "update" ? "Info rekening sudah diupdate" : "Perubahan rekening akan diproses",
					showCloseButton: true,
				});
				this.closeOPT();
				$("#bankid").prop("disabled", true);
				$("#no_rek").prop("readonly", true);
				$("#atasnama").prop("readonly", true);
				$("#telepon").prop("readonly", true);
			}
		});
	},
	
	submitForm() {
		console.log('this.form.otpType',this.form.otpType);
		$("#submit").prop("disabled", true);
		$("#update").prop("disabled", true);
        this.loadingContent = true;
		this.submitting2 = true;
		const e = this.$refs;
		var data = Object.keys(this.form)
			.map(
			(key) =>
				encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
			)
			.join("&");
		// data = data + '&pin_no=' + pinSet;
		this.method = "PUT";
		let urlSubmit = this.status != "update" ? "/users/profile/rekening/" + this.form.id : "/payment/saldo/req_change_rek";

		authFetch(urlSubmit, {
			method: this.method,
			body: data,
		})
		.then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {
			}
			return res.json();
		})
		.then((js) => {
			this.loadingPin = false;
			this.errors = [];
			if (!js.success) {
				for (var key in js.details) {
					if (js.details.hasOwnProperty(key) && js.close) {
						this.errors.push(`${js.details[key]}`);
						Swal.fire({
							title: "Update Gagal",
							icon: "error",
							text: js.details[key],
							showCloseButton: true,
						});
					}else{
						Swal.fire({
							title: "Update Gagal",
							icon: "error",
							text: js.details[key],
							showCloseButton: true,
						});
					}
				}
				$("#update").prop("disabled", false);
				$("#submit").prop("disabled", false);
			} else {
				Swal.fire({
					title: this.status != "update" ? "Update Berhasil" : "Pengajuan Berhasil",
					icon: "success",
					text: this.status != "update" ? "Info rekening sudah diupdate" : "Perubahan rekening akan diproses",
					showCloseButton: true,
				});
				localStorage.removeItem(`expiredToken-${this.form.otpType}`);
				// this.closeOPT();
				$("#bankid").prop("disabled", true);
				$("#no_rek").prop("readonly", true);
				$("#atasnama").prop("readonly", true);
				$("#telepon").prop("readonly", true);
			}
			// this.loadingContent = false;
			// this.submitting2 = false;
		});
	},
	cancelRequest() {
		this.loadingContent = true;
		Swal.fire({
			title: "Batalkan Pengajuan Rekening?",
			showCancelButton: true,
			confirmButtonText: "Ya",
			cancelButtonText: "Tidak",
		}).then((result) => {
			if (result.isConfirmed) {
				this.method = "PUT";
				let urlSubmit = "/payment/saldo/unreq_change_rek";

				authFetch(urlSubmit, {
					method: this.method
				})
				.then((res) => {
					if (res.status === 201) {
					} else if (res.status === 400) {
					}
					return res.json();
				})
				.then((js) => {
					this.errors = [];
					if (!js.success) {
						console.log(js.details);

						for (var key in js.details) {
							if (js.details.hasOwnProperty(key)) {
								this.errors.push(js.details[key]);
							}
						}

						return;
					} else {
						Swal.fire({
							title: "Berhasil Membatalkan Pengajuan",
							icon: "success",
							text: "Info rekening batal diajukan",
							showCloseButton: true,
						});

						this.status = "update";
						$("#bankid").prop("disabled", false);
						$("#no_rek").prop("readonly", false);
						$("#atasnama").prop("readonly", false);
						$("#telepon").prop("readonly", false);
					}
					this.loadingContent = false;
				});

				ev.preventDefault();
			} else {
				this.loadingContent = false;
			}
		});
	},
	browsefile: function (argument) {
		document.getElementById("inputFile").click();
	},
	uploadFoto: function (file) {
		// var info = auth.user();
		var file_data = $("#inputFile").prop("files");
		var form_data = new FormData();
		var fileInput = $("#inputFile")[0];
		this.readURL(fileInput);
		$.each(fileInput.files, function (k, file) {
			form_data.append("file", file);
		});
		$("#loading-avatar").show();
		form_data.append("subdir", "/ktp/" + this.form.id);
		form_data.append("id", this.form.id);

		authFetch("/users/profile/upload_ktp", {
			method: "POST",
			body: form_data,
			headers: {
				"Content-Type": null,
			},
		})
		.then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {
			}
			return res.json();
		})
		.then((js) => {
			this.errors = [];
			if (!js.status) {
				for (var key in js.details) {
					if (js.details.hasOwnProperty(key)) {
						this.errors.push(js.details[key]);
					}
				}

				return;
			}

			$("#loading-avatar").hide();
		});
	},
	readURL: function (input) {
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e) {
				$("#imgPreview").attr("src", e.target.result);
			};
			reader.readAsDataURL(input.files[0]); // convert to base64 string
		}
	},
	removeAvatar: function () {
		// var info = auth.user();
		authFetch("/shipper/remove_ktp/" + this.form.id, {
			method: "POST",
			headers: {
				"Content-Type": null,
			},
		})
		.then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {
			}
			return res.json();
		})
		.then((js) => {
			$("#img_id").val("");
			$("#img_code").val("");
			$("#imgPreview").attr(
			"src",
			window.$apiUrl+"/uploads/default/unknown_user.jpg"
			);
			$(".user-profile").addClass("empty-profile");
		});
	},
},
components: {
vSelect,
Pin,
OneTimePassword
},
mounted() {},
};
</script>
<style type="text/css">
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile+label {
	font-size: 1.25em;
	font-weight: 700;
	color: white;
	/*background-color: black;*/
	display: inline-block;
}

.inputfile:focus+label,
.inputfile+label:hover {
	/*background-color: red;*/
}

.inputfile+label {
	cursor: pointer;
	/* "hand" cursor */
}

.inputfile:focus+label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.user-profile .center-profile {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 36px;
	height: 36px;
	transition: opacity 0.3s;
	line-height: 34px;
	text-align: center;
	text-decoration: none;
}

.user-profile .btn-profile {
	display: block;
	width: 36px;
	height: 36px;
	transition: opacity 0.3s;
	border-radius: 50%;
	background-color: #fff;
	color: #606975;
	line-height: 34px;
	box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
	cursor: pointer;
}

.user-profile .remove-profile {
	/*visibility: hidden !important;*/
	cursor: pointer;
}
</style>